var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "b-tabs",
                  {
                    attrs: {
                      pills: "",
                      vertical: "",
                      "nav-class": "p-0",
                      "nav-wrapper-class": "col-sm-2",
                      "content-class": "pt-0 px-2 text-muted"
                    }
                  },
                  [
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Requête prise en charge",
                          active: "",
                          "title-item-class": "mb-2"
                        },
                        on: {
                          click: function($event) {
                            _vm.tab = "Prise En Charge"
                          }
                        }
                      },
                      [
                        _vm.tab == "Prise En Charge"
                          ? _c("Table", { attrs: { model: _vm.tab } })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Requête Demande de dotation",
                          "title-item-class": "mb-2"
                        },
                        on: {
                          click: function($event) {
                            _vm.tab = "Demande de dotation"
                          }
                        }
                      },
                      [
                        _vm.tab == "Demande de dotation"
                          ? _c("Table", { attrs: { model: _vm.tab } })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Requête Demande repas emporté",
                          "title-item-class": "mb-2"
                        },
                        on: {
                          click: function($event) {
                            _vm.tab = "Demande Repas Emporté"
                          }
                        }
                      },
                      [
                        _vm.tab == "Demande Repas Emporté"
                          ? _c("Table", { attrs: { model: _vm.tab } })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Requête Demande de maintenance",
                          "title-item-class": "mb-2"
                        },
                        on: {
                          click: function($event) {
                            _vm.tab = "Demande de Maintenance"
                          }
                        }
                      },
                      [
                        _vm.tab == "Demande de Maintenance"
                          ? _c("Table", { attrs: { model: _vm.tab } })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Ordres de Services (ODS)",
                          "title-item-class": "mb-2"
                        },
                        on: {
                          click: function($event) {
                            _vm.tab = "Ordre de Service"
                          }
                        }
                      },
                      [
                        _vm.tab == "Ordre de Service"
                          ? _c("Table", { attrs: { model: _vm.tab } })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }