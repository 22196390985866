<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Table from "./components/table";

import { fetchSettings, fetchRolesListApi } from "@/api/common";
export default {
  page: {
    title: "Niveaux de Validations",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: { Layout, PageHeader, Table },
  import: { fetchSettings, fetchRolesListApi },

  mounted() {},

  methods: {
    
  },
  data() {
    return {
      title: "Niveaux de validation",
      submitted: false,
      showHelpModal: true,
      rolesList: [],
      tab:'Prise En Charge',
      items: [
        {
          text: "Paramètres",
          to: { name: "base.settings.index" },
        },
        {
          text: "Niveaux de validation",
          active: false,
        },
      ],
    };
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs
              pills
              vertical
              nav-class="p-0"
              nav-wrapper-class="col-sm-2"
              content-class="pt-0 px-2 text-muted"
            >
              <b-tab
                title="Requête prise en charge"
                active
                title-item-class="mb-2"
                @click="tab = 'Prise En Charge'"
              >
                <Table v-if="tab == 'Prise En Charge'" :model="tab"></Table>
                
              </b-tab>

              <b-tab
                title="Requête Demande de dotation"
                title-item-class="mb-2"
                @click="tab = 'Demande de dotation'"
              >
                <Table v-if="tab == 'Demande de dotation'" :model="tab"></Table>

              </b-tab>

              <b-tab
                title="Requête Demande repas emporté"
                title-item-class="mb-2"
                @click="tab = 'Demande Repas Emporté'"
              >
                <Table v-if="tab == 'Demande Repas Emporté'" :model="tab"></Table>

              </b-tab>

              <b-tab
                title="Requête Demande de maintenance"
                title-item-class="mb-2"
                @click="tab = 'Demande de Maintenance'"
              >
                <Table v-if="tab == 'Demande de Maintenance'" :model="tab"></Table>
              </b-tab>

              <b-tab
                title="Ordres de Services (ODS)"
                title-item-class="mb-2"
                @click="tab = 'Ordre de Service'"
              >
                <Table v-if="tab == 'Ordre de Service'" :model="tab"></Table>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
