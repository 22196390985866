import { render, staticRenderFns } from "./table.vue?vue&type=template&id=3f2d42f2&"
import script from "./table.vue?vue&type=script&lang=js&"
export * from "./table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bahaaeddine/public_html/hmd/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3f2d42f2')) {
      api.createRecord('3f2d42f2', component.options)
    } else {
      api.reload('3f2d42f2', component.options)
    }
    module.hot.accept("./table.vue?vue&type=template&id=3f2d42f2&", function () {
      api.rerender('3f2d42f2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/base/settings/beta/validation_levels/components/table.vue"
export default component.exports